<template>
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <slot name="header"> </slot>
                <slot name="footer">
          <v-btn class="buttonColor" color="red"   @click="drop">Supprimer</v-btn>
          <v-btn @click="close">Fermer</v-btn>
          <v-btn class="buttonColor" color="green" @click="save">Enregistrer</v-btn>

        </slot>
      </header>
      <section class="modal-body">
        <ConfirmModal v-if="this.confirmModal" :id=this.id />
        <slot name="body">
          <div class="formInput" v-for="(data, index) in datas" :key="index">
            <label class="formLabel" :for="data[0]"> {{modifiedKeys[data[0]]}}</label>
            <br>
            <input v-if="data[0] != 'CATEGORIE'" v-model="datas[index][1]" />
            <select v-if="data[0] == 'CATEGORIE'" v-model="datas[index][1]" class="input">
            <option disabled value="">Choisissez une categorie</option>
                <option v-for="cate in categories" :key="cate" :value="cate"  :selected="cate === datas[index][1]">{{ cate }} </option>
          </select>
          </div>
        </slot>
      </section>
      <footer class="modal-footer">
        <slot name="footer">
          <v-btn class="buttonColor"  color="red"  @click="drop">Supprimer</v-btn>
          <v-btn   @click="close">Fermer</v-btn>
          <v-btn class="buttonColor"   color="green"  @click="save">Enregistrer</v-btn>

        </slot>
      </footer>
    </div>
    <span
      v-if="this.sendSucess"
      v-bind:class="{
        success: sendSucess == 'Données enregistrées',
        failure: sendSucess == 'Erreur enregistrement',
      }"
      ><br />{{ this.sendSucess }}</span
    >
  </div>
</template>
<script>
import { bus } from "../main";
import ConfirmModal from "../components/ConfirmModal"
const categories = [
  "Agrotoponyme",
  "Anthropotoponyme",
  "Ecotoponyme",
  "Ethnotoponyme",
  "Géotoponyme",
  "Hagiotoponyme",
  "Hydrotoponyme",
  "Idéotoponyme / Toponymes abstrait",
  "Métaphore",
  "Mythotoponyme",
  "Néotoponyme",
  "Odonyme",
  "Orotoponyme",
  "Phènomènes atmosphèriques",
  "Phytotoponyme",
  "Toponyme Historique",
  "Toponyme Obscur",
  "Toponyme transféré",
  "Toponymes religieux",
  "Zootoponyme",
];
const mk = {
                'ID': 'ID',
                'ROWID': 'ROWID',
                'O_TO' : 'Forme graphique corse',
                'O_TO_V1' : 'Variante forme graphique corse (1)',
                'O_TO_V2' : 'Variante forme graphique corse (2)',
                'O_NE' : 'Nom de l’enquêteur',
                'O_NEE' : 'Nom du locuteur (informateur)',
                'O_DATE' : 'Date de l’enquête (année)',
                'O_SON' : 'Son recueilli',
                'O_PHON' : 'Forme phonique',
                'O_ETHN' : 'Ethnonyme',
                'OFF_TO' : 'Toponyme INSEE',
                'OFF_COM' : 'Nom de commune INSEE',
                'OFF_HAM' : 'Nom du hameau INSEE',
                'OFF_INSEE' : 'INSEE', 
                'CR_TO' : 'Toponyme du cadastre rénové',
                'CR_NE' : 'Nom du collecteur',
                'CR_NUM' : 'Numéros de parcelle (CR)',
                'CR_NUM2' : 'Numéros de parcelle (suite) (CR)',
                'CR_SEC' : 'Sections de parcelle (CR)',
                'CR_DATE' : 'Année de rénovation',
                'CN_TO' : 'Toponyme du cadastre napoléonien',
                'CN_NE' : 'Nom du collecteur (CN)',
                'CN_NUM' : 'Numéros de parcelle (CN)',
                'CN_SEC' : 'Sections de parcelle (CN)',
                'CN_DATE' : 'Année (CN)',
                'CN_REF' : 'Référence (CN)',
                'PT_TO' : 'Toponyme du Plan Terrier',
                'PT_NE' : 'Nom du collecteur (PT)',
                'PT_DATE' : 'Année (PT)',
                'PT_REF' : 'Référence des rouleaux (PT)',
                'AR_TO' : 'Toponyme de l’Archive',
                'AR_NE' : 'Nom du collecteur (AR)',
                'AR_TYPE' : 'Type d’archive',
                'AR_DATE' : 'Date de l’archive',
                'AR_REF' : 'Référence de l’archive',
                'PU_TO' : 'Toponyme de source publiée',
                'PU_NE' : 'Nom du collecteur (PU)',
                'PU_TYPE' : 'Type de publication',
                'PU_DATE' : 'Date de la publication',
                'PU_REF' : 'Référence bibliographique',
                'ANA_CLAS' : 'Classification générale',
                'ANA_THEMG' : 'Thème général',
                'ANA_THEM' : 'Thème',
                'ANA_SSTH' : 'Sous-thème',
                'ANA_SIGN' : 'Type de *signifié',
                'ANA_LEM1' : 'Lemme 1',
                'ANA_LEM2' : 'Lemme 2',
                'ANA_ETHY' : 'Etymologie',
                'ANA_REFETY' : 'Référence bibliographique de l’étymologie',
                'ANA_DET' : 'Présence d’un déterminant',
                'ANA_RAD' : 'Radical',
                'ANA_SUF' : 'Préfixe, suffixe, infixe',
                'ANA_MC' : 'Mot composé',
                'ANA_REF' :  'Référent du toponyme',
                'ANA_ETHN' : 'Ethnonyme(2)',
                'ANA_ARCH' : 'Archéologie (oui/non)',
                'ANA_GRPER' : 'Grandes Périodes',
                'ANA_OBS' : 'Observation',
                'ANA_OBS1' : 'OBS1', 
                'CONFI' :  'Niveau de confidentialité',
                'HP_1' : 'Code BDLC1',
                'HP_2' : 'Code BDLC2',
                'ID_PERS' : 'Nom du transcripteur',
                'VALIDE' : 'Toponyme validé ?',
                'CLIENT' : 'Visible par le client (nom)',
                'CATEGORIE' : 'Catégorie',

}
export default {
  name: "modal",
  props: ["id"],
  components:{
    ConfirmModal
  },
  data() {
    return {
      datas: [],
      form: [],
      sendSucess: "",
      confirmModal: false,
      categories: []
    };
  },
  methods: {
    close() {
      bus.$emit("close");
    },
    drop() {
      this.confirmModal = true
    },
    save() {
      var url = process.env.VUE_APP_API_HOST + "putDatas/" + this.id;
      const putMethod = {
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({ add: this.datas }),
      };

      fetch(url, putMethod)
        .then((response) => response.json())
        .then(() => {
          this.sendSucess = "Données enregistrées";
          setTimeout(() => {
            console.log("timeout");
            this.sendSucess = "";
            bus.$emit("save");
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          this.sendSucess = "Erreur Enregistrement";
          setTimeout(() => {
            console.log("timeout");
            this.sendSucess = "";
          }, 2000);
        });
    },
  },
  async created() {
    this.categories= categories;
    bus.$on("close", () =>{this.confirmModal = false});
    var response = await fetch(
      process.env.VUE_APP_API_HOST + "getToponymeById/" + this.id
    );
    var datas = await response.json();
    Object.entries(datas.properties).forEach((element) => {
      this.datas.push(element);
    });
    this.modifiedKeys = mk;
  },
};
</script>



<style scoped>

@import url('https://fonts.cdnfonts.com/css/cooper-hewitt?styles=34279');

.formInput{

  margin-bottom: 20px;
}

.btnText {
  color: white;
}

.formLabel{
  font-size: 20px;
  font-weight: bolder;
}

.modal-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 70vh;
  padding: 10px;
  width: 60%;
}

.buttonColor{
  color: white;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #4aae9b;
  justify-content: center;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: center;
}

.modal-body {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  
}



input {
  border: solid 1px black;
  min-width: 300px;
  border-radius: 2px;
  height: 30px;
  margin-left: 5%;
  font-size: 20px;
}

.input {
  border: solid 1px black;
  min-width: 300px;
  border-radius: 2px;
  height: 30px;
  margin-left: 5%;
  font-size: 20px;
}

.success{
  position: fixed;
  bottom: 20px;
  height: 50px;
  width: auto;
  background-color:green;
  color: white;
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24); 
  border-radius: 2px; 
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  text-align: center;
  z-index: 999;
}

.failure {
  position: fixed;
  bottom: 20px;
  height: 50px;
  width: auto;
  background-color:red;
  color: white;
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24); 
  border-radius: 2px; 
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  text-align: center;

}

</style>