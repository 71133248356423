<template>
<div class="addForm">
  <h2 class="addFormTitle">Fiche Toponymique</h2>
  <br>
  <div class="tableMap">
          <div>
        <v-btn small color="error" @click="cancel">ANNULER</v-btn>
        <v-btn small class="saveBtn" color="green" @click="add" >ENREGISTRER</v-btn>

      </div>
    <form v-on:submit.prevent >
      <div class="table">
        <div v-for="key in keys" :key="key">
          <label :for=key>{{modifiedKeys[key]}}</label><br>
          <input v-if="key == 'OFF_COM'" type="text" class="input" v-model="commune">
          <input v-if="key != 'OFF_COM' && key != 'CATEGORIE'"  type="text" class="input" v-model="form[key]">
          <select v-if="key == 'CATEGORIE'" v-model="form['CATEGORIE']" class="input">
            <option disabled value="">Choisissez une categorie</option>
            <option v-for="categorie in categories" :key='categorie' :value="categorie">{{categorie}}</option>
          </select>
        </div>
        <div class="lat">
          <label for="latitude">LATITUDE</label><br>
          <input type="text" v-model="this.lat">
        </div>
        <div>
          <label for="longitude">LONGITUDE</label><br>
          <input type="text" v-model="this.long">
        </div>
      </div>
      <br>
      <div>
        <v-btn small color="error" @click="cancel">ANNULER</v-btn>
        <v-btn small class="saveBtn" color="green" @click="add" >ENREGISTRER</v-btn>

      </div>
      <span v-if="this.sendSucess" v-bind:class="{success: sendSucess == 'Données enregistrées', failure: sendSucess == 'Erreur enregistrement' }"><br>{{this.sendSucess}}</span>
    </form>
  </div>
  </div>
</template>

<script>
import {bus} from '../main'
import {store, mutations} from './Store';
const mk = {
                'ID': 'ID',
                'ROWID': 'ROWID',
                'O_TO' : 'Forme graphique corse',
                'O_TO_V1' : 'Variante forme graphique corse (1)',
                'O_TO_V2' : 'Variante forme graphique corse (2)',
                'O_NE' : 'Nom de l’enquêteur',
                'O_NEE' : 'Nom du locuteur (informateur)',
                'O_DATE' : 'Date de l’enquête (année)',
                'O_SON' : 'Son recueilli',
                'O_PHON' : 'Forme phonique',
                'O_ETHN' : 'Ethnonyme',
                'OFF_TO' : 'Toponyme INSEE',
                'OFF_COM' : 'Nom de commune INSEE',
                'OFF_HAM' : 'Nom du hameau INSEE',
                'OFF_INSEE' : 'INSEE', 
                'CR_TO' : 'Toponyme du cadastre rénové',
                'CR_NE' : 'Nom du collecteur',
                'CR_NUM' : 'Numéros de parcelle (CR)',
                'CR_NUM2' : 'Numéros de parcelle (suite) (CR)',
                'CR_SEC' : 'Sections de parcelle (CR)',
                'CR_DATE' : 'Année de rénovation',
                'CN_TO' : 'Toponyme du cadastre napoléonien',
                'CN_NE' : 'Nom du collecteur (CN)',
                'CN_NUM' : 'Numéros de parcelle (CN)',
                'CN_SEC' : 'Sections de parcelle (CN)',
                'CN_DATE' : 'Année (CN)',
                'CN_REF' : 'Référence (CN)',
                'PT_TO' : 'Toponyme du Plan Terrier',
                'PT_NE' : 'Nom du collecteur (PT)',
                'PT_DATE' : 'Année (PT)',
                'PT_REF' : 'Référence des rouleaux (PT)',
                'AR_TO' : 'Toponyme de l’Archive',
                'AR_NE' : 'Nom du collecteur (AR)',
                'AR_TYPE' : 'Type d’archive',
                'AR_DATE' : 'Date de l’archive',
                'AR_REF' : 'Référence de l’archive',
                'PU_TO' : 'Toponyme de source publiée',
                'PU_NE' : 'Nom du collecteur (PU)',
                'PU_TYPE' : 'Type de publication',
                'PU_DATE' : 'Date de la publication',
                'PU_REF' : 'Référence bibliographique',
                'ANA_CLAS' : 'Classification générale',
                'ANA_THEMG' : 'Thème général',
                'ANA_THEM' : 'Thème',
                'ANA_SSTH' : 'Sous-thème',
                'ANA_SIGN' : 'Type de *signifié',
                'ANA_LEM1' : 'Lemme 1',
                'ANA_LEM2' : 'Lemme 2',
                'ANA_ETHY' : 'Etymologie',
                'ANA_REFETY' : 'Référence bibliographique de l’étymologie',
                'ANA_DET' : 'Présence d’un déterminant',
                'ANA_RAD' : 'Radical',
                'ANA_SUF' : 'Préfixe, suffixe, infixe',
                'ANA_MC' : 'Mot composé',
                'ANA_REF' :  'Référent du toponyme',
                'ANA_ETHN' : 'Ethnonyme(2)',
                'ANA_ARCH' : 'Archéologie (oui/non)',
                'ANA_GRPER' : 'Grandes Périodes',
                'ANA_OBS' : 'Observation',
                'ANA_OBS1' : 'OBS1', 
                'CONFI' :  'Niveau de confidentialité',
                'HP_1' : 'Code BDLC1',
                'HP_2' : 'Code BDLC2',
                'ID_PERS' : 'Nom du transcripteur',
                'VALIDE' : 'Toponyme validé ?',
                'CLIENT' : 'Visible par le client (nom)',
                'CATEGORIE' : 'Catégorie',
}

const categories = [
  "Agrotoponyme",
  "Anthropotoponyme",
  "Ecotoponyme",
  "Ethnotoponyme",
  "Géotoponyme",
  "Hagiotoponyme",
  "Hydrotoponyme",
  "Idéotoponyme / Toponymes abstrait",
  "Métaphore",
  "Mythotoponyme",
  "Néotoponyme",
  "Odonyme",
  "Orotoponyme",
  "Phènomènes atmosphèriques",
  "Phytotoponyme",
  "Toponyme Historique",
  "Toponyme Obscur",
  "Toponyme transféré",
  "Toponymes religieux",
  "Zootoponyme",
];
export default {

  data() {
   return {
     keys: [],
     form: [],
     lat:"",
     long:"",
     sendSucess: "",
     reload: false,
     commune: "",
     modifiedKeys:[],
     categories: [],
     categorieSelected: "",
     
   }
  },
  methods:{
    cancel(){
      mutations.setAddFormOpen(false);
      bus.$emit("cancel");
    },
    add(){
      var postBody={};        
      Object.entries(this.keys).forEach((key)=>{
          if(this.form[key[1]]){
            if(key[1] == "ROWID" || key[1] == "ID"){
              postBody[key[1]] = Number(this.form[key[1]])
            }else{
              postBody[key[1]] = this.form[key[1]]
          }
          }else{
            if(key[1] == "ID") postBody[key[1]] = Number(Date.now());
            else postBody[key[1]] = null
          }
        })
      var url = process.env.VUE_APP_API_HOST + "postDatas/"
      const postMethod = {
          method: 'POST', 
          headers: {
            'Content-type': 'application/json; charset=UTF-8' 
          },
          body:JSON.stringify({
            "type" : "Feature",
            "properties" : postBody,
            "geometry": {
                "type": "Point",
                "coordinates": [
                  this.long,
                  this.lat
                ]
            }
           } )
      }
      fetch(url, postMethod)
      .then(response => response.json())
      .then(() =>{ 
        this.sendSucess = "Données enregistrées";
        this.form = [];
        this.lat ="";
        this.long = "";
        setTimeout(() => {
          this.sendSucess="";
          bus.$emit('add');
          }, 2000);
      }) 
      .catch(err =>{
        console.log(err);
        this.sendSucess = "Erreur Enregistrement";
        setTimeout(() => {
          this.sendSucess=""}, 2000);
      } ) 
    } 
  },
  async created(){   
    this.categories = categories; 
    const response = await fetch(process.env.VUE_APP_API_HOST + "getkeys");
    let data = await response.json();
    console.log(data)
    data.forEach(element => {
        this.keys.push(element)
    })

      bus.$on("markerCommune", ()=>{

          this.commune = store.markerCommune;
          this.form["OFF_COM"] = store.markerCommune;
      
    })

    bus.$on("addPosition", (data)=>{
      this.lat = data.position.lat;
      this.long = data.position.lng;
    })
    this.modifiedKeys = mk;
  },
  mounted(){

  },

}
</script>

<style scoped>
@import url('https://fonts.cdnfonts.com/css/cooper-hewitt?styles=34279');

.addForm {
    top: -15px;
    left: 70px;
    border-radius: 0 30px 30px 0 !important;
    background-color: #FFF2DF !important;
    z-index: 9999;
    height: 100vh;
    min-width: 270px;
    display: block;
  overflow: auto;
  position: absolute;
  padding: 10px;
  padding-left: 15px;
  font-family: 'Cooper Hewitt', sans-serif;
  font-weight: 707;
}



.addFormTitle {
  font-weight: 707;
}

.tableMap{
  display: flex;
  flex-direction: column;
}

.input {
  border: solid 1px black;
  min-width: 200px;
  border-radius: 2px;
  height: 30px;
  margin-right: 5px;
  
}

input {
  border: solid 1px black;
  min-width: 200px;
  border-radius: 2px;
  height: 30px;
}

.saveBtn {
  color: white;
}

.table{
  display: flex;
  flex-direction: column;
width: 100%;
}

.table imput {
margin:auto; }


.success{
  position: fixed;
  bottom: 20px;
  height: 50px;
  width: auto;
  background-color:green;
  color: white;
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24); 
  border-radius: 2px; 
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  text-align: center;
  z-index: 999;
}

.failure{
  position: fixed;
  bottom: 20px;
  height: 50px;
  width: auto;
  background-color:red;
  color: white;
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24); 
  border-radius: 2px; 
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  text-align: center;

}


  
</style>