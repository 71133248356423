<template >
    <div>
        
        <v-card class="filterCard"> 

            <div class="filterHead">
            <v-card-title class="cardTitle">Filtrer</v-card-title>
            <v-btn small color="orange darken-3" :outlined="isOutlined" class="btnSelectAll" @click="changeCheckState">{{ this.checkState }}</v-btn>
                </div>


    <ul class="filterList">
                <li class="filter" v-for="nameFilter in nameFilters" :key="nameFilter.dbName">
                    <input type="checkbox" :value="nameFilter.dbName" @change="handleCheckbox" v-model="selected">
                    <label class="labelChecklist" :for="nameFilter.showName">{{ nameFilter.showName }}</label>
                </li>
            </ul>


        </v-card>
    </div>
</template>
<script>

import {bus} from '../main'
import { store, mutations } from './Store';
export default {
    methods: {
         getFilterButton () {
          var button = document.getElementById("filterBtn");
          var position = button.getBoundingClientRect();
          var bottom = position.bottom ;
          //var left = position.left; 
          var card = document.getElementsByClassName("filterCard");
          card[0].style.top = bottom + "px";

        },

        handleCheckbox () {
            this.setFilterCard(this.selected);
            bus.$emit ("handleCheckbox");
            console.log(this.getFiltersSelected)
        },

        setFilterCard(array){
            mutations.setFilterCard(array);
        },

        changeCheckState() {
            if (this.checkState == "Tout cocher") {
                this.checkState = "Tout décocher";
                this.selected = []
                this.isOutlined = false;
                this.nameFilters.forEach(nameFilter => {
                    this.selected.push(nameFilter.dbName);
                })
                this.handleCheckbox();
            }
            else if (this.checkState == "Tout décocher") {
                this.selected = [];
                this.checkState = "Tout cocher";
                this.isOutlined = true;
                this.handleCheckbox();
            }
        },
    },
    
    mounted() {
        this.getFilterButton();
        bus.$on("fullSizeTableEvent", ()=>{this.getFilterButton()})
        bus.$on('homeEvent', () =>  {
            this.getFilterButton()
        })

        if (store.wasFilterCardOpenedOnce == false) {
            mutations.setWasFilterCardOpenedOnce(true);
            this.nameFilters.forEach(nameFilter => {
                this.selected.push(nameFilter.dbName);
            })
            this.setFilterCard(this.selected);
            this.checkState = 'Tout décocher';
        }
        else {
            this.selected = this.getFiltersSelected;
            if(store.filtersSelected.length == 0) {
                this.checkState = 'Tout cocher';
            }
            else {
                this.checkState = 'Tout décocher';
            }
        }

    },
    computed:{
        getFiltersSelected(){
            return store.filtersSelected
        }
},
    data(){
        return{
            checkState: '',
            selected:[],
    nameFilters: [
  {"dbName": "ID", "showName": "ID"},
  {"dbName": "ROWID", "showName": "ROWID"},
  {"dbName": "O_TO", "showName": "Forme graphique corse"},
  {"dbName": "O_TO_V1", "showName": "Variante forme graphique corse (1)"},
  {"dbName": "O_TO_V2", "showName": "Variante forme graphique corse (2)"},
  {"dbName": "O_NE", "showName": "Nom de l’enquêteur"},
  {"dbName": "O_NEE", "showName": "Nom du locuteur (informateur)"},
  {"dbName": "O_DATE", "showName": "Date de l’enquête (année)"},
  {"dbName": "O_SON", "showName": "Son recueilli"},
  {"dbName": "O_PHON", "showName": "Forme phonique"},
  {"dbName": "O_ETHN", "showName": "Ethnonyme"},
  {"dbName": "OFF_TO", "showName": "Toponyme INSEE"},
  {"dbName": "OFF_COM", "showName": "Nom de commune INSEE"},
  {"dbName": "OFF_HAM", "showName": "Nom du hameau INSEE"},
  {"dbName": "OFF_INSEE", "showName": "INSEE"},
  {"dbName": "CR_TO", "showName": "Toponyme du cadastre rénové"},
  {"dbName": "CR_NE", "showName": "Nom du collecteur"},
  {"dbName": "CR_NUM", "showName": "Numéros de parcelle (CR)"},
  {"dbName": "CR_NUM2", "showName": "Numéros de parcelle (suite) (CR)"},
  {"dbName": "CR_SEC", "showName": "Sections de parcelle (CR)"},
  {"dbName": "CR_DATE", "showName": "Année de rénovation"},
  {"dbName": "CN_TO", "showName": "Toponyme du cadastre napoléonien"},
  {"dbName": "CN_NE", "showName": "Nom du collecteur (CN)"},
  {"dbName": "CN_NUM", "showName": "Numéros de parcelle (CN)"},
  {"dbName": "CN_SEC", "showName": "Sections de parcelle (CN)"},
  {"dbName": "CN_DATE", "showName": "Année (CN)"},
  {"dbName": "CN_REF", "showName": "Référence (CN)"},
  {"dbName": "PT_TO", "showName": "Toponyme du Plan Terrier"},
  {"dbName": "PT_NE", "showName": "Nom du collecteur (PT)"},
  {"dbName": "PT_DATE", "showName": "Année (PT)"},
  {"dbName": "PT_REF", "showName": "Référence des rouleaux (PT)"},
  {"dbName": "AR_TO", "showName": "Toponyme de l’Archive"},
  {"dbName": "AR_NE", "showName": "Nom du collecteur (AR)"},
  {"dbName": "AR_TYPE", "showName": "Type d’archive"},
  {"dbName": "AR_DATE", "showName": "Date de l’archive"},
  {"dbName": "AR_REF", "showName": "Référence de l’archive"},
  {"dbName": "PU_TO", "showName": "Toponyme de source publiée"},
  {"dbName": "PU_NE", "showName": "Nom du collecteur (PU)"},
  {"dbName": "PU_TYPE", "showName": "Type de publication"},
  {"dbName": "PU_DATE", "showName": "Date de la publication"},
  {"dbName": "PU_REF", "showName": "Référence bibliographique"},
  {"dbName": "ANA_CLAS", "showName": "Classification générale"},
  {"dbName": "ANA_THEMG", "showName": "Thème général"},
  {"dbName": "ANA_THEM", "showName": "Thème"},
  {"dbName": "ANA_SSTH", "showName": "Sous-thème"},
  {"dbName": "ANA_SIGN", "showName": "Type de *signifié"},
  {"dbName": "ANA_LEM1", "showName": "Lemme 1"},
  {"dbName": "ANA_LEM2", "showName": "Lemme 2"},
  {"dbName": "ANA_ETHY", "showName": "Etymologie"},
  {"dbName": "ANA_REFETY", "showName": "Référence bibliographique de l’étymologie"},
  {"dbName": "ANA_DET", "showName": "Présence d’un déterminant"},
  {"dbName": "ANA_RAD", "showName": "Radical"},
  {"dbName": "ANA_SUF", "showName": "Préfixe, suffixe, infixe"},
  {"dbName": "ANA_MC", "showName": "Mot composé"},
  {"dbName": "ANA_REF", "showName": "Référent du toponyme"},
  {"dbName": "ANA_ETHN", "showName": "Ethnonyme(2)"},
  {"dbName": "ANA_ARCH", "showName": "Archéologie (oui/non)"},
  {"dbName": "ANA_GRPER", "showName": "Grandes Périodes"},
  {"dbName": "ANA_OBS", "showName": "Observation"},
  {"dbName": "ANA_OBS1", "showName": "OBS1"},
  {"dbName": "CONFI", "showName": "Niveau de confidentialité"},
  {"dbName": "HP_1", "showName": "Code BDLC1"},
  {"dbName": "HP_2", "showName": "Code BDLC2"},
  {"dbName": "ID_PERS", "showName": "Nom du transcripteur"},
  {"dbName": "VALIDE", "showName": "Toponyme validé ?"},
  {"dbName": "CLIENT", "showName": "Visible par le client (nom)"},
  {"dbName": "CATEGORIE", "showName": "Categorie"}
]
        }
    }
    
}
</script>
<style scoped>

@import url('https://fonts.cdnfonts.com/css/cooper-hewitt?styles=34279');

.filterList {
    list-style-type: none;
    height: 95%;
    display: flex;
flex-direction: column;
flex-wrap: wrap;
column-gap: 15px;
}


.filterCard {
width: 94vw;
height: 66vh;
position: absolute;
z-index: 9999;
background-color: beige;
overflow: auto;
right: 5px;
}

.filter {
    display: flex;
    align-items: center;
}

.filterHead {
    display: flex;
    align-items: center;
    
}

.cardTitle {
    font-weight: 707 ;
    font-family: 'Cooper Hewitt', sans-serif;
}
.btnSelectAll {
    color: white !important;
    font-weight: 707;
    font-family: 'Cooper Hewitt', sans-serif;

}
    
</style>