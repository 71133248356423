<template>
  <div class="maingroup">      
    <div class="map">
      <Map :key="this.mapReload"/>
    </div>
    <div class="board" :key="this.boardReload">
      <Board />
    </div>
  </div>
</template>

<script>
import {bus} from "../main"
import Board from '../components/Board'
import Map from "../components/Map"
import firebase from 'firebase/compat/app';
//import UserSnap from '../components/UserSnap.vue';

export default {
  components:{
    Board,
    Map,
    
  },
  
    data() {
    return {
      boardReload: 0,
      mapReload: false,
      auth: false,
      loader: false,

    }
  },
  methods: {
    
    load(){
      this.loader = !this.loader
    },
    report(){
      window.open("https://collector.usersnap.com/a1e7dadc-5abb-45ec-ab4f-fed488d64302", '_blank');

    }
  },
  mounted(){
    if(firebase.auth().currentUser) this.auth = true;
    /*this.loader = true;
    setTimeout(()=>{
      this.loader = false;
    }, 4000)*/

  },
  async created(){
    bus.$on("load", ()=>{
      console.log("load");
      this.load();
    })
  }
}
</script>
<style >
.maingroup {
  display: flex;
  flex-direction: row;
}

.map {
  flex-basis: 40%;
} 
</style>
