
<template>
  <div v-if="totalPages > 0" class="pagination-wrapper">
    <v-pagination v-model="internalPage" :length="totalPages" :total-visible="7"></v-pagination>
  </div>
</template>

<script>
export default {
  name: "pagination",
  props: ["datas", "currentPage", "pageSize"],
  data() {
    return {
      internalPage: this.currentPage
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.datas.length / this.pageSize);
    }
  },
  methods: {
    updatePage() {
      this.$emit("page:update", this.internalPage);
    }
  },
  watch: {
    internalPage(newPage) {
      if (newPage !== this.currentPage) {
        this.updatePage();
      }
    },
    currentPage(newPage) {
      this.internalPage = newPage;
    }
  }
};
</script>


<style>
.pagination-wrapper {
  text-align: center;
  font-size: 20px;

  background-color: #EF6C00;
}

.pagination-btn {
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
}
</style>