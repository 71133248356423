<template >
  <div id="container">
    <div class="mapControls">

      <Addform v-if="this.addForm" :key="this.keyAddForm" />

      <div id="mapContainer"></div>
    </div>
    <div class="markerControls">
      <v-btn rounded @click="handleDrag" v-bind:class="{ drag: isDraggable, unDrag: !isDraggable, btnPosition: true }">
        Modifier position
      </v-btn>


    </div>

    <Modal :id="this.id" v-if="this.modal" />
  </div>
</template>

<script>
const categories = [
  "Agrotoponyme",
  "Anthropotoponyme",
  "Ecotoponyme",
  "Ethnotoponyme",
  "Géotoponyme",
  "Hagiotoponyme",
  "Hydrotoponyme",
  "Idéotoponyme / Toponymes abstrait",
  "Métaphore",
  "Mythotoponyme",
  "Néotoponyme",
  "Odonyme",
  "Orotoponyme",
  "Phènomènes atmosphèriques",
  "Phytotoponyme",
  "Toponyme Historique",
  "Toponyme Obscur",
  "Toponyme transféré",
  "Toponymes religieux",
  "Zootoponyme",
];
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Icon } from "leaflet";
import { bus } from "../main";
import Modal from "./Modal.vue";
import Addform from "./Addform.vue";
import { store, mutations } from "./Store";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster/dist/leaflet.markercluster-src";
import "leaflet-canvas-markers";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  iconSize: [20, 32],
});
var mapDiv;
var markers;
var markersList = [];
var addMarkerGroup;
export default {
  name: "Map",
  components: {
    Modal,
    Addform,
  },
  data() {
    return {
      locations: [],
      isDraggable: false,
      modal: false,
      id: "",
      reload: false,
      mapCenterPosition: {},
      addForm: false,
      communesSud: [],
      communesNord: [],
      selected: [],
      locationsByCommunes: [],
      allLocationsByCommunesNonFiltered: [],
      keyAddForm: 0,
      buttonDisable: false,
      categories: [],
      categorieSelected: [],
      locationsByCategorie: [],
      locationsByCommunesNonFiltered: [],
      count: 0,
      allCategories: "Tout cocher"
    };
  },
  methods: {
    setupLeafletMap() {
      var cadastre = L.tileLayer(
        "https://data.geopf.fr/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=PCI vecteur&TILEMATRIXSET=PM&FORMAT=image/png&LAYER=CADASTRALPARCELS.PARCELLAIRE_EXPRESS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
        {
          minZoom: 0,
          maxZoom: 18,
          attribution: "IGN-F/Geoportail",
          tileSize: 256, // les tuiles du Géooportail font 256x256px
        }
      );
      var ign = L.tileLayer(
        "https://data.geopf.fr/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/png&LAYER=GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
        {
          minZoom: 0,
          maxZoom: 18,
          attribution: "IGN-F/Geoportail",
          tileSize: 256, // les tuiles du Géooportail font 256x256px
        }
      );
      var sat = L.tileLayer(
        "https://data.geopf.fr/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/jpeg&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
        {
          minZoom: 0,
          maxZoom: 18,
          attribution: "IGN-F/Geoportail",
          tileSize: 256, // les tuiles du Géooportail font 256x256px
        }
      );
      var photos50 = L.tileLayer(
        "https://data.geopf.fr/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/png&LAYER=ORTHOIMAGERY.ORTHOPHOTOS.1950-1965&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
        {
          minZoom: 0,
          maxZoom: 18,
          attribution: "IGN-F/Geoportail",
          tileSize: 256, // les tuiles du Géooportail font 256x256px

        }
      );
      var terrier = L.tileLayer(
        "https://data.geopf.fr/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=nolegend&TILEMATRIXSET=PM&FORMAT=image/png&LAYER=GEOGRAPHICALGRIDSYSTEMS.TERRIER_V1&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
        {
          minZoom: 0,
          maxZoom: 18,
          attribution: "IGN-F/Geoportail",
          tileSize: 256, // les tuiles du Géooportail font 256x256px

        }
      );
      var ign25 = L.tileLayer(
        "https://data.geopf.fr/private/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/jpeg&LAYER=GEOGRAPHICALGRIDSYSTEMS.MAPS.SCAN25TOUR&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&apikey=ign_scan_ws ",
        {
          minZoom: 0,
          maxZoom: 18,
          attribution: "IGN-F/Geoportail",
          tileSize: 256, // les tuiles du Géooportail font 256x256px
        }
      );

      var baseMaps = {
        IGN: ign,
        Vue_Satellite: sat,
        Années50: photos50,
        Plan_Terrier: terrier,
        IGN25: ign25,
      };
      var overlayMap = {
        Cadastre_rénové: cadastre,
      };

      mapDiv = L.map("mapContainer", { preferCanvas: true })
        .setView(
          [
            this.position.lat == undefined ? 42 : this.position.lat,
            this.position.lng == undefined ? 9 : this.position.lng,
          ],
          this.zoom == undefined ? 8 : this.zoom
        )
        .on("moveend", () => {
          this.setPosition(mapDiv.getCenter());
        })
        .on("zoomend", () => {
          this.setZoom(mapDiv.getZoom());

        })
        .on("baselayerchange", (layer) => {

          if (layer.name == "ign") this.setLayer(layer.name);
          else if (layer.name == "sat") this.setLayer(layer.name);
          else if (layer.name == "Plan_terrier") this.setLayer(layer.name);
          else if (layer.name == "Années50") {
            this.setLayer(layer.name);
            /* let myLabels = document.querySelectorAll(".myLabels");
             console.log(myLabels)
             myLabels.forEach(el =>{
               el.classList.remove("myLabels");
               el.classList.add("myLabels50");
             })*/

          }
        })
        .on("overlayadd", (add) => {
          if (add.name == "cadastre") this.setCadastre("true");
        })
        .on("overlayremove", (remove) => {
          if (remove.name == "cadastre") this.setCadastre("false");
        })
        .on("move", () => {
          console.log("move")
          if (mapDiv.getZoom() >= 15 && this.categorieSelected.length > 0) {
            setTimeout(() => {
              console.log("add")
              this.addIcon();
              //this.handleCategorieSelect();

            }, 500);
          }
        })
        .whenReady(() => { });
      if (this.layer == "ign" || this.layer == "") ign.addTo(mapDiv);
      else if (this.layer == "sat") sat.addTo(mapDiv);
      if (this.cadastre == "true") cadastre.addTo(mapDiv);
      L.control.scale({ imperial: false }).addTo(mapDiv);

      L.control.layers(baseMaps, overlayMap).addTo(mapDiv);

      this.addToponymesOnMap();
    },
    async addCommuneToForm(position) {
      let lat = position.lat;
      let long = position.lng;
      let url = "https://geo.api.gouv.fr/communes";
      const response = await fetch(url + "?lon=" + long + "&lat=" + lat);
      const adress = await response.json();
      this.setMarkerCommune(adress[0].nom.toUpperCase());
      bus.$emit("markerCommune");
    },
    clearMap() {
      markersList.forEach((element) => {
        mapDiv.removeLayer(element);
      });
      markersList = [];
      this.setToponymeCount(0);
    },


    selectAllCategories() {
      if (this.allCategories == "Tout cocher") {
        this.allCategories = "Tout décocher";
        this.categorieSelected = []
        this.categories.forEach(categorie => {
          this.categorieSelected.push(categorie);
        })
        this.handleCategorieSelect();
      }
      else if (this.allCategories == "Tout décocher") {
        this.categorieSelected = [];
        this.allCategories = "Tout cocher";
        this.handleCategorieSelect();

      }
    },
    addToponymesOnMap() {

      if (markers != undefined) markers.clearLayers();
      this.clearMap();
      if (
        this.selectedCommunes.length < 10 &&
        this.locationsByCommunes.flat(3).length < 700
      ) {
        let markers = L.layerGroup();
        this.locationsByCommunes.forEach((commune) => {
          commune[1].forEach((toponyme) => {
            var icon = L.divIcon({
              className:
                "a-class " +
                toponyme.properties.CATEGORIE,
              iconSize: L.circle({ radius: 100 }),
              //html: "<strong>" + toponyme.properties.O_TO + "</strong>"
            });
            let marker = L.marker(
              [
                toponyme.geometry.coordinates[1],
                toponyme.geometry.coordinates[0],
              ],
              { icon: icon }
            )
              .on("dblclick", () => {
                this.modal = true;
                this.id = toponyme.properties.ID;
              })
              .on("dragend", (e) => {
                var marker = e.target;
                var position = marker.getLatLng();
                this.save(toponyme.properties.ID, position);
              })
              .bindTooltip(toponyme.properties.O_TO, {
                interactive: true,
                permanent: true,
                className: "myLabels",
                direction: "right",
                offset: [1, 10],
              });
            markers.addLayer(marker);

            markersList.push(marker);
          });
          mapDiv.addLayer(markers);
          this.setToponymeCount(markersList.length);

        });
        bus.$emit("load");
      } else {
        markers = [];
        markersList = [];
        markers = L.markerClusterGroup({
          maxClusterRadius: 50,
          disableClusteringAtZoom: 15,
        });
        this.locationsByCommunes.forEach((commune) => {

          commune[1].forEach((toponyme) => {
            var icon = L.divIcon({
              className: "a-class " +
                toponyme.properties.CATEGORIE,
              iconSize: L.circle({ radius: 100 }),
              //html: "<strong>" + toponyme.properties.O_TO + "</strong>"
            });
            let marker = L.marker(
              [
                toponyme.geometry.coordinates[1],
                toponyme.geometry.coordinates[0],
              ],
              { icon: icon }
            )
              .on("dblclick", () => {
                this.modal = true;
                this.id = toponyme.properties.ID;
              })
              .on("dragend", (e) => {
                var marker = e.target;
                var position = marker.getLatLng();
                this.save(toponyme.properties.ID, position);

                //mapDiv.panTo(new L.LatLng(position.lat, position.lng));
              })
              .bindTooltip(toponyme.properties.O_TO, {
                interactive: true,
                permanent: true,
                className: "myLabels",
                direction: "right",
                offset: [5, 5],
              });
            markersList.push(marker);
            markers.addLayer(marker);
          });
          mapDiv.addLayer(markers);
          this.setToponymeCount(markersList.length);
        });
        bus.$emit("load");
      }
      if (this.categorieSelected.length > 0) {
        this.addIcon();
      } else {
        this.removeIcon();

      }
    },
    changeColor(e) {
      console.log(e)
      let labels = document.querySelectorAll(".myLabels");
      labels.forEach(label => {
        label.style.color = e.target.value
      })
    },
    shareDatas(id, position) {
      bus.$emit("datas", {
        id: id,
        position: position,
      });
    },
    shareAddPosition(position) {
      bus.$emit("addPosition", { position: position });
    },
    handleDrag() {
      this.isDraggable = !this.isDraggable;
      if (this.isDraggable) {
        this.draggable();
      } else {
        this.unDraggable();
      }
    },
    handleCategorieSelect() {
      if (this.categorieSelected.length == 0) {
        this.getToponymesByCommunes();
        return;
      }
      this.locationsByCategorie.length = 0;
      var locationsByCategorieLocal = [];
      var temp = [];
      console.log(this.locationsByCommunesNonFiltered)
      this.locationsByCommunesNonFiltered.forEach((location) => {
        temp = [];
        this.categorieSelected.forEach((categorie) => {
          location[1].forEach((l) => {
            if (l.properties.CATEGORIE == categorie && !temp.includes(l)) temp.push(l);
            else if (l.properties.CATEGORIE == null && !temp.includes(l)) temp.push(l);
          });
        });
        locationsByCategorieLocal.push([location[0], temp]);
      });
      this.locationsByCategorie = locationsByCategorieLocal;
      this.filterByCategorie();
    },
    addIcon() {
      var toponymes = document.querySelectorAll('.a-class');
      console.log(toponymes)
      toponymes.forEach(toponyme => {
        toponyme.classList.add("map-marker");
      })
    },
    removeIcon() {
      var toponymes = document.querySelectorAll('.a-class');
      toponymes.forEach(toponyme => {
        toponyme.classList.remove("map-marker");
      })
    },
    filterByCategorie() {
      this.locationsByCommunes = this.locationsByCategorie;
      this.addToponymesOnMap();
    },
    draggable() {
      markersList.forEach((element) => {
        element.setIcon(
          L.divIcon({
            className: "test",
          })
        );
        if (element.dragging != undefined) element.dragging.enable();
      });
    },
    unDraggable() {
      markersList.forEach((element) => {
        if (this.categorieSelected.length == 0) {
          element.setIcon(
            L.divIcon({
              className: "noIcon",
            })
          );
        }
        if (element.dragging != undefined) element.dragging.disable();
      });
      if (this.categorieSelected.length != 0)
        this.handleCategorieSelect();
    },
    setCadastre(c) {
      mutations.setCadastre(c);
    },
    setPosition(p) {
      mutations.setPosition(p);
    },
    setZoom(z) {
      mutations.setZoom(z);
    },
    setLayer(l) {
      mutations.setLayer(l);
    },
    setMarkerCommune(commune) {
      mutations.setMarkerCommune(commune);
    },
    setToponymeCount(number) {
      mutations.setToponymeCount(number)
    },
    addMarker() {

      this.buttonDisable = true;
      addMarkerGroup = L.layerGroup();
      var marker = L.marker(
        [
          this.position.lat == undefined ? 42 : this.position.lat,
          this.position.lng == undefined ? 9 : this.position.lng,
        ],
        { draggable: true, opacity: 1 }
      )
        .on("add", (e) => {
          setTimeout(() => {
            var marker = e.target;
            var position = marker.getLatLng();
            this.shareAddPosition(position);
            this.addCommuneToForm(position);
          }, 500);
        })
        .on("dragend", (e) => {
          var marker = e.target;
          var position = marker.getLatLng();
          this.shareAddPosition(position);
          this.addCommuneToForm(position);
        });
      addMarkerGroup.addLayer(marker);
      mapDiv.addLayer(addMarkerGroup);
    },
    save(id, position) {
      var url = process.env.VUE_APP_API_HOST + "putCoordinates/" + id;
      const putMethod = {
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          id: id,
          lat: position.lat,
          lng: position.lng,
        }),
      };

      fetch(url, putMethod)
        .then((response) => response.json())
        .then(() => {
          this.sendSucess = "Données enregistrées";
          setTimeout(() => {
            this.sendSucess = "";
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          this.sendSucess = "Erreur Enregistrement";
          setTimeout(() => {
            this.sendSucess = "";
          }, 2000);
        });
    },
    async getAllToponymesByCommunes() {
      const byCommunes = await fetch(
        process.env.VUE_APP_API_HOST + "getAllToponymesByCommune"
      );
      let dataByCommunes = await byCommunes.json();
      this.locationsByCommunes = dataByCommunes;
      this.addToponymesOnMap();
    },
    async getAllToponymesByCommunesNonFiltered() {
      const byCommunes = await fetch(
        process.env.VUE_APP_API_HOST + "getAllToponymesByCommune"
      );
      let dataByCommunes = await byCommunes.json();
      this.allLocationsByCommunesNonFiltered = dataByCommunes;
    },
    async getToponymesByCommunes() {
      var arrayOfCommunes = [];
      this.locationsByCommunes = [];
      let communes = this.selectedCommunes;
      await Promise.all(
        communes.map(async (commune) => {
          const response = await fetch(
            process.env.VUE_APP_API_HOST + "getToponymesByCommune/" + commune
          );
          let data = await response.json();
          arrayOfCommunes[commune.toUpperCase()] = data;
          this.locationsByCommunes = Object.entries(arrayOfCommunes);
          this.locationsByCommunesNonFiltered = Object.entries(arrayOfCommunes);
          this.addToponymesOnMap();
        })
      );
    },
    reloadMap() {
      if (addMarkerGroup != undefined) addMarkerGroup.clearLayers();

      setTimeout(() => {
        if (this.selectedCommunes.length < 10) this.getToponymesByCommunes();
        else this.getAllToponymesByCommunes();
      }, 500);
    },
    async getAllToponymes() {
      const response = await fetch(
        process.env.VUE_APP_API_HOST + "getAllToponymes"
      );
      let data = await response.json();
      this.locations = data;
      //this.setupLeafletMap();
    },

    filterMap(filter) {
      if (filter.length == 0) {
        this.getAllToponymesByCommunes();
        return;
      }
      this.locationsByCommunes = [];
      this.allLocationsByCommunesNonFiltered.forEach((commune) => {
        let com = [];
        for (let i = 0; i <= commune[1].length; i++) {
          if (
            typeof commune[1][i] !== "undefined" &&
            commune[1][i].properties["O_TO"] != null
          )
            if (
              commune[1][i].properties["O_TO"]
                .toLowerCase()
                .includes(filter.toLowerCase())
            ) {
              com.push(commune[1][i]);
            }
        }
        this.locationsByCommunes.push([commune[0], com]);
      });
      this.addToponymesOnMap();
    },
  },

  async created() {
    this.categories = categories;
    this.getAllToponymesByCommunesNonFiltered();
    this.getToponymesByCommunes();
    /*bus.$on("markerCommune", ()=>{
      this.keyAddForm +=1;
    })*/

    bus.$on("localisate", (newPosition) => {
      var element = document.getElementById("test");
        element.classList.remove("fullsizetable");
        var element2 = document.getElementById("mapContainer");
        element2.classList.remove("fullsizemap");
        bus.$emit("homeEvent");
        bus.$emit("resizeMap");
      let p = new L.LatLng(newPosition.latitude, newPosition.longitude);
      setTimeout(() => {
        mapDiv.panTo(p);
        mapDiv.invalidateSize();

      }, 1000);

      mapDiv.setZoom(17);
    });
    bus.$on("close", () => {
      this.modal = false;
    });
    bus.$on("mapCenterPosition", (position) => {
      var parsedPosition = JSON.parse(JSON.stringify(position));
      this.mapCenterPosition = parsedPosition;
    });

    bus.$on("resizeMap", () => {
      mapDiv.invalidateSize();
    })
    bus.$on('fullSizeTableEvent', () => {
      mapDiv.invalidateSize();
    })

    bus.$on("delete", () => {
      this.reloadMap();
    });

    bus.$on("boardSave", () => {
      this.reloadMap();
    });

    bus.$on("cancel", () => {
      this.addForm = false;
      this.buttonDisable = false;

      this.reloadMap();
    });

    bus.$on("add", () => {
      this.addForm = false;
      mutations.setAddFormOpen(false);
      this.buttonDisable = false;
      this.reloadMap();
    });

    bus.$on("save", () => {
      this.reloadMap();
    });

    bus.$on("addFormEvent", () => {
      this.addForm = !this.addForm;
      mutations.setAddFormOpen(this.addForm);
      if (this.addForm)
        this.addMarker();
      else
        bus.$emit("cancel");
    });

    bus.$on("elementFilterEvent", () => {
      //this.addForm= false;
    });
  },

  computed: {
    selectedCommunes() {
      return store.selectedCommunes;
    },
    position() {
      return store.position;
    },
    zoom() {
      return store.zoom;
    },
    layer() {
      return store.layer;
    },
    cadastre() {
      return store.cadastre;
    },
  },

  async mounted() {
    this.setupLeafletMap();

    bus.$on("communesFilter", async () => {
      let communes = this.selectedCommunes;
      if (communes.length == 0) {
        if (markers != undefined) markers.clearLayers();
        this.locationsByCommunes = [];
        this.allLocationsByCommunesNonFiltered = [];
        this.locationsByCommunesNonFiltered = [];
        this.categorieSelected = [];
        this.addToponymesOnMap();
      } else if (communes.length > 100) {
        if (markers != undefined) markers.clearLayers();
        this.locationsByCommunes = [];
        this.allLocationsByCommunesNonFiltered = [];
        this.getAllToponymesByCommunes();

      } else if (communes.length > 0 && communes.length <= 100) {
        if (markers != undefined) markers.clearLayers();
        this.getToponymesByCommunes();
      }
      if (this.categorieSelected.length > 0) {
        setTimeout(() => {
          this.handleCategorieSelect();

        }, 1000);
      }
    });
  },
};
</script>

<style >
@import url('https://fonts.cdnfonts.com/css/cooper-hewitt?styles=34279,34271');


#container {
  position: sticky;
  left: 0;
}

.alertToponymes {
  width: 20%;
  position: absolute;
  z-index: 700;
  right: 50px;
}

#mapContainer {
  margin-left: 80px;
  margin-right: 10px;
  margin-top: 15px;
  width: 50vw;
  height: 95vh;
  border-radius: 5px;
}

.btnPosition {
  position: absolute;
  bottom: 5px;
  z-index: 700;
  color: white !important;
  font-family: 'Cooper Hewitt', sans-serif;
  font-weight: 707;
}

.count {
  font-weight: bolder;
}

.myLabels {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-weight: 711;
  font-size: 1.6em;
  font-family: 'Cooper Hewitt', sans-serif;

  /*color: rgb(107, 255, 238);
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: black;
  font-size: 1.6em;
  text-shadow: 0 0 7px rgba(0,0,0, 0.5);
  text-shadow: -1px 0 0 rgba(0, 0, 0, 0.8), 
             1px 0 0 rgba(0, 0, 0, 0.8),
             0 -1px 0 rgba(0, 0, 0, 0.8),
             0 1px 0 rgba(0, 0, 0, 0.8);*/
  color: #FFFFFF;
  text-shadow: 0.5px 0.5px 2px #00ACC1, 0.5px -0.5px 2px #00ACC1, -0.5px 0.5px 2px #00ACC1, -0.5px -0.5px 2px #00ACC1, 0.5px 0px 2px #00ACC1, 0px 0.5px 2px #00ACC1, -0.5px 0px 2px #00ACC1, 0px -0.5px 2px #00ACC1;
}

.myLabels50 {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-weight: bolder;
  color: white;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: black;
  font-size: 1.5em;
}

.drag {
  background-color: green !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.notDisabled {
  pointer-events: auto;
  opacity: 1;
}

.unDrag {
  background-color: red !important;
}

.noIcon {
  opacity: 0;
}


.markerControls {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}

.addMarkerButton:disabled {
  filter: brightness(0.9);
}

.filters {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.map-marker {
  background: gray;
  width: 5px;
  height: 20px;
}

.leaflet-tooltip-right:before {
  border: none !important;
}

.test {
  background: green;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
}

.Agrotoponyme {
  background: rgb(255, 235, 15);
}

.Anthropotoponyme {
  background: rgb(250, 112, 174);
}

.Ecotoponyme {
  background: saddlebrown;
}

.Ethnotoponyme {
  background: sandybrown;
}

.Géotoponyme {
  background: rgb(102, 122, 143);
}

.Hagiotoponyme {
  background: rgb(151, 1, 1);
}

.Hydrotoponyme {
  background: blue;
}

.Orotoponyme {
  background: rgb(58, 58, 58);
}

.Phytotoponyme {
  background: green;
}

.religieux {
  background: indianred;
}

.Zootoponyme {
  background: brown;
}
</style>