<script>
import { bus } from "../main";
export default {
  name: "ConfirmModal",
  props: ["id"],
  data() {
    return {
      sendSucess: "",
    };
  },
  methods: {
    close() {
      bus.$emit("close");
    },
    drop() {
      fetch(process.env.VUE_APP_API_HOST + "delete/" + this.id, {
        method: "DELETE",
      }).then((result) => {
        if (result.status == 200) {
          this.sendSucess = "Données enregistrées";
          setTimeout(() => {
            bus.$emit("close");
            this.sendSucess = "";
            bus.$emit("delete");
          }, 2000);
        } else console.log(result);
      });
    },

  },

};
</script>

<template>
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <slot name="header"> </slot>
      </header>
      <section class="modal-body">
          <h2>Etes-vous sur de vouloir supprimer?</h2>
      </section>
      <footer class="modal-footer">
        <slot name="footer">
          <v-btn outlined @click="close">Annuler</v-btn>
          <v-btn color="red" class="textColor" @click="drop">Supprimer</v-btn>

        </slot>
      </footer>
    </div>
    <span
      v-if="this.sendSucess"
      v-bind:class="{
        success: sendSucess == 'Données enregistrées',
        failure: sendSucess == 'Erreur enregistrement',
      }"
      ><br />{{ this.sendSucess }}</span
    >
  </div>
</template>

<style scoped>

@import url('https://fonts.cdnfonts.com/css/cooper-hewitt?styles=34279');

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 200px;
 
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #4aae9b;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.modal-body h2 {
  font-weight: 707;
  font-family: "Cooper Hewitt", sans-serif;
}


.textColor {
  color: white;
}


input {
  border: solid 1px black;
  min-width: 400px;
  border-radius: 2px;
  height: 30px;
  margin-left: 5%;
}
.success{
  position: fixed;
  bottom: 20px;
  height: 50px;
  width: auto;
  background-color:green;
  color: white;
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24); 
  border-radius: 2px; 
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  text-align: center;
  z-index: 999;
}

.failure{
  position: fixed;
  bottom: 20px;
  height: 50px;
  width: auto;
  background-color:red;
  color: white;
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24); 
  border-radius: 2px; 
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  text-align: center;

}

</style>