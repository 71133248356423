<template>
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <h2 style="color: black">Recherche avancée</h2>

        <slot name="header"> </slot>
        <slot name="footer"> </slot>
      </header>
      <section class="modal-body">
        <div class="searchForm">
          <table>
            <tr>
              <td><label for="columns">Colonne</label></td>
              <td><label for="conditions">Condition</label></td>
              <td><span>Filtre</span></td>
            </tr>
            <tr>
              <td>
                <select class="modern-select" style="height:30px; border-radius: 2px"  name="columns" id="colonne">
                  <option
                    v-for="(col, index) in modifiedKeys"
                    :key="index"
                    :value="col"
                  >
                    {{ col }}
                  </option>
                </select>
              </td>
              <td>
                <select class="modern-select" style="height:30px; border-radius: 2px" name="conditions" id="conditions">
                  <option value="contain">Contient</option>
                  <option value="start">Commence par</option>
                  <option value="end">Fini par</option>
    
                </select>
              </td>
              <td><input type="text" value="" class="textInput" /></td>
            </tr>
          </table>
          
        </div>
      </section>
      <v-btn color="orange" class="textColor" @click="add">Ajouter un filtre</v-btn>
      <label for="logical">Opération logique</label>
      <br>
      <select class="modern-select" style="width: 100px; height: 30px; border-radius: 2px" name="logical" v-model="logical">
        
        <option value="and">Et</option>
        <option value="or">Ou</option>
      </select>
      <footer class="modal-footer">
        <slot name="footer">
          <v-btn color="red" class="textColor" @click="close">
            Fermer
          </v-btn>
          <v-btn color="cyan" class="textColor" @click="filter">
            Rechercher
          </v-btn>
          
        </slot>
      </footer>
    </div>
    <span
      v-if="this.sendSucess"
      v-bind:class="{
        success: sendSucess == 'Données enregistrées',
        failure: sendSucess == 'Erreur enregistrement',
      }"
      ><br />{{ this.sendSucess }}</span
    >
  </div>
</template>
<script>
import { bus } from "../main";
const categories = [
  "Agrotoponyme",
  "Anthropotoponyme",
  "Ecotoponyme",
  "Ethnotoponyme",
  "Géotoponyme",
  "Hagiotoponyme",
  "Hydrotoponyme",
  "Idéotoponyme / Toponymes abstrait",
  "Métaphore",
  "Mythotoponyme",
  "Néotoponyme",
  "Odonyme",
  "Orotoponyme",
  "Phènomènes atmosphèriques",
  "Phytotoponyme",
  "Toponyme Historique",
  "Toponyme Obscur",
  "Toponyme transféré",
  "Toponymes religieux",
  "Zootoponyme",
];
const mk = {
  ID: "ID",
  ROWID: "ROWID",
  O_TO: "Forme graphique corse",
  O_TO_V1: "Variante forme graphique corse (1)",
  O_TO_V2: "Variante forme graphique corse (2)",
  O_NE: "Nom de l’enquêteur",
  O_NEE: "Nom du locuteur (informateur)",
  O_DATE: "Date de l’enquête (année)",
  O_SON: "Son recueilli",
  O_PHON: "Forme phonique",
  O_ETHN: "Ethnonyme",
  OFF_TO: "Toponyme INSEE",
  OFF_COM: "Nom de commune INSEE",
  OFF_HAM: "Nom du hameau INSEE",
  OFF_INSEE: "INSEE",
  CR_TO: "Toponyme du cadastre rénové",
  CR_NE: "Nom du collecteur",
  CR_NUM: "Numéros de parcelle (CR)",
  CR_NUM2: "Numéros de parcelle (suite) (CR)",
  CR_SEC: "Sections de parcelle (CR)",
  CR_DATE: "Année de rénovation",
  CN_TO: "Toponyme du cadastre napoléonien",
  CN_NE: "Nom du collecteur (CN)",
  CN_NUM: "Numéros de parcelle (CN)",
  CN_SEC: "Sections de parcelle (CN)",
  CN_DATE: "Année (CN)",
  CN_REF: "Référence (CN)",
  PT_TO: "Toponyme du Plan Terrier",
  PT_NE: "Nom du collecteur (PT)",
  PT_DATE: "Année (PT)",
  PT_REF: "Référence des rouleaux (PT)",
  AR_TO: "Toponyme de l’Archive",
  AR_NE: "Nom du collecteur (AR)",
  AR_TYPE: "Type d’archive",
  AR_DATE: "Date de l’archive",
  AR_REF: "Référence de l’archive",
  PU_TO: "Toponyme de source publiée",
  PU_NE: "Nom du collecteur (PU)",
  PU_TYPE: "Type de publication",
  PU_DATE: "Date de la publication",
  PU_REF: "Référence bibliographique",
  ANA_CLAS: "Classification générale",
  ANA_THEMG: "Thème général",
  ANA_THEM: "Thème",
  ANA_SSTH: "Sous-thème",
  ANA_SIGN: "Type de *signifié",
  ANA_LEM1: "Lemme 1",
  ANA_LEM2: "Lemme 2",
  ANA_ETHY: "Etymologie",
  ANA_REFETY: "Référence bibliographique de l’étymologie",
  ANA_DET: "Présence d’un déterminant",
  ANA_RAD: "Radical",
  ANA_SUF: "Préfixe, suffixe, infixe",
  ANA_MC: "Mot composé",
  ANA_REF: "Référent du toponyme",
  ANA_ETHN: "Ethnonyme(2)",
  ANA_ARCH: "Archéologie (oui/non)",
  ANA_GRPER: "Grandes Périodes",
  ANA_OBS: "Observation",
  ANA_OBS1: "OBS1",
  CONFI: "Niveau de confidentialité",
  HP_1: "Code BDLC1",
  HP_2: "Code BDLC2",
  ID_PERS: "Nom du transcripteur",
  VALIDE: "Toponyme validé ?",
  CLIENT: "Visible par le client (nom)",
  CATEGORIE: "Catégorie",
};
export default {
  name: "modal",
  components: {},
  data() {
    return {
      datas: [],
      form: [],
      sendSucess: "",
      categories: [],
      picked: [],
      count: 1,
      logical: "and",
    };
  },
  methods: {
    add() {
      this.count++;
      let searchForm = document.getElementsByClassName("searchForm")[0];
      let modalBody = document.getElementsByClassName("modal-body")[0];
      let clone = searchForm.cloneNode(true);
      modalBody.appendChild(clone);
      let searchFormsList = document.querySelectorAll(".textInput");
      let last = searchFormsList[searchFormsList.length - 1];
      last.value = "";
      /*let logicalsList = document.querySelectorAll(".logicalSpan");
      let lastLogical = logicalsList[logicalsList.length - 1];
      lastLogical.textContent = "";*/
    },
    addAnd(event) {
      console.log(event);
      let parent = event.target.parentNode;
      let children = event.target.parentNode.children;
      console.log(children);
      if (children.length > 2) {
        children[children.length - 1].textContent = "";
        children[children.length - 1].textContent = event.target.value;
      } else {
        let span = document.createElement("span");
        span.className = "logicalSpan";
        span.innerHTML = event.target.value;
        parent.append(span);
      }
    },
    addOr(event) {
      let parent = event.target.parentNode;
      let children = event.target.parentNode.children;
      console.log(children);
      if (children.length > 2) {
        children[children.length - 1].textContent = "";
        children[children.length - 1].textContent = event.target.value;
      } else {
        let span = document.createElement("span");
        span.className = "logicalSpan";

        span.innerHTML = event.target.value;
        parent.append(span);
      }
    },
    close() {
      bus.$emit("closeAdvanced");
    },
    async filter() {
      let searchForms = document.querySelectorAll(".searchForm");
      var requests = [];
      searchForms.forEach((element) => {
        let request = {};
        var colonne =
          element.firstChild.children[1].children[0].firstChild.value;
        let condition =
          element.firstChild.children[1].children[1].firstChild.value;
        let entree =
          element.firstChild.children[1].children[2].firstChild.value;

        let entries = Object.entries(mk);
        var key = entries.filter(element =>{
          return element[1] == colonne
        })
        console.log(key[0][0]);
        request = {
          colonne: key[0][0],
          condition: condition,
          entree: entree,
          logicalOp: this.logical,
        };
        requests.push(request);
      });
      var url = process.env.VUE_APP_API_HOST + "advancedSearch/";
      const postMethod = {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(requests),
      };
      let response = await fetch(url, postMethod);
      let advancedSearch = await response.json();
      bus.$emit("advancedSearch", advancedSearch);

    },
  },
  async created() {
    this.categories = categories;

    this.modifiedKeys = mk;
    this.picked[this.count] = "";
    console.log(this.count);
  },
};
</script>



<style scoped>
.formInput {
  margin-bottom: 20px;
}

.formLabel {
  font-size: 20px;
  font-weight: bolder;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.textColor {
  color: white;
}

.modern-select {
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 5px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, .04);
  border-radius: .5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;

}
.modern-select::-ms-expand {
    display: none;
}
.modern-select:hover {
    border-color: #888;
}
.modern-select:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
}
.modern-select option {
    font-weight: normal;

}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 70vh;
  padding: 10px;
  width: 8 0%;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #4aae9b;
  justify-content: center;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: center;
}

.modal-body {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
}




input {
  border: solid 1px black;
  min-width: 300px;
  border-radius: 2px;
  height: 30px;
  margin-left: 5%;
  font-size: 20px;
}

.input {
  border: solid 1px black;
  min-width: 300px;
  border-radius: 2px;
  height: 30px;
  margin-left: 5%;
  font-size: 20px;
}


td,
th {
  height: 30px;
  border-top: none;
}

.searchForm{
  border-bottom: 1px solid #eeeeee;
}
</style>